import crypt from "@/utils/crypt"

export default class appService {
	constructor(http) { this.http = http }
	signup(params) {

		const requestBody = {
			email: params.email,
			password: crypt.hash(params.password),
			rgpd_consent: params.rgpd_consent
		};
	
		// Check if params.phone exists before adding it to the request body
		if (params.phone) {
			requestBody.phone = params.phone;
		}
		return this.http.post(`/c/signup`,requestBody);
	}
	signin(params) {
		return this.http.post(`/c/signin`,{email:params.email,password:crypt.hash(params.password)});
	}
	logout(){
		return this.http.get(`/c/logout`)
	}
	reset(email){
		return this.http.post(`/c/reset`, {"email":email})
	}
	changeOldPassword(old,newpass){
		return this.http.post(`/c/reset-password`, {"old":crypt.hash(old), "new":crypt.hash(newpass)})
	}
	changeNewPassword(token,newpass){
		return this.http.post(`/c/reset-password`, {"new":crypt.hash(newpass), token: token})
	}
	changePassword(token){
		return this.http.get(`/c/reset-password/`+token)
	}
	verifyAccount(token){
		return this.http.get(`/c/verify/`+token)
	}
	retryAccount(){
		return this.http.get(`/c/retry_verification`)
	}
}