<template lang="">
	<ParentForm
		v-if=" advanced && advanced.fields "
		:title="!$siteconf.cooptation? $t('Form') : $t('FormCoop') "
		headerClass=" border-bottom "
		@update="editform($event)"
		:disabled="isDisabled"
	>
		<p
			v-if="savebyTokenError"
			class="text-danger text-center"
		>
			{{ $t(`resetpasswordError`) }}
		</p>
		<div>
			<div
				class="mx-3 spaced-2"
				v-for="(field,index) in advanced.fields"
				:key="field.type"
			>
				<div
					class=""
					v-if="field.type=='text'"
				>
					<p class="body1 mb-3 ">
						{{ field.label }}
					</p>
					<MatInput
						:class="{'pe-none': isDisabled}"
						v-model="formdata[field.key]"
						dense
						class="me-3 w-100"
						:label="field.label"
						:required="field.mandatory"
					/>
				</div>
				<div
					class="d-flex mt-5"
					v-if="field.type=='date'"
				>
					<p class="body1 me-3 d-flex align-items-center ">
						{{ field.label }}
					</p>
					<MatDatepicker
						:class="{'pe-none': isDisabled}"
						v-model="formdata[field.key]"
						dense
						class="w-50"
						:label="field.label"
						:required="field.mandatory"
					/>
				</div>
				<div
					class="d-flex "
					v-if="field.type=='select1'"
				>
					<p
						class="body1 me-3 col-6"
					>
						{{ field.label }} <span v-if="field.mandatory">*</span>
					</p>
					<div class="d-flex  col-6">
						<MatRadioGroup
							:class="{'pe-none': isDisabled}"
							class="flex-row flex-wrap form-input"
							:name="'Ratio'+index"
							v-model="formdata[field.key]"
						>
							<MatFormfield
								v-for="val in field.values"
								:key="val"
								:label="val"
							>
								<MatRadio :value="val" />
							</MatFormfield>
						</MatRadioGroup>
					</div>
				</div>
				<div
					class="row"
					v-if="field.type=='selectn'"
				>
					<p
						class="body1 col-12 col-lg-3"
					>
						{{ field.label }} <span v-if="field.mandatory">*</span>
					</p>
					<MatCheckboxGroup
						:class="{'pe-none': isDisabled}"
						:required="field.mandatory"
						:name="'Checkbox'+index"
						v-model="formdata[field.key]"
						class="d-flex col-12 col-lg-9"
					>
						<div class=" flex-row flex-wrap form-input">
							<span
								v-for="val in field.values"
								:key="val"
							>
								<MatFormfield
									:label="val"
									class=" "
								>
									<MatCheckbox :value="val" />
								</MatFormfield></span>
						</div>
					</MatCheckboxGroup>
				</div>
				<div
					class="w-60"
					v-if="field.type=='dropdown'"
				>
					<MatSelect
						:class="{'pe-none': isDisabled}"
						:required="field.mandatory"
						v-model="formdata[field.key]"
						class="w-100"
						dense
						:label="field.label"
					>
						<MatListItem />
						<MatListItem
							v-for="val in field.values"
							:key="val"
							:value="val"
							:label="val"
						/>
					</MatSelect>
				</div>
				<div
					v-if="field.type=='long'"
					class="mt-3"
				>
					<p class="body1 mb-3">
						{{ field.label }}
					</p>
					<MatTextarea
						:class="{'pe-none': isDisabled}"
						v-model="formdata[field.key]"
						:required="field.mandatory"
						:label="field.label"
						rows="5"
						class="w-100"
					/>
				</div>
				<div
					v-if="field.type=='range'"
					class="  mt-3"
				>
					<p class="body1 d-flex align-items-center me-2 ">
						{{ field.label }} <span v-if="field.mandatory">*</span>
					</p>
					<MatSlider
						:class="{'pe-none': isDisabled}"
						:required="field.mandatory"
						class="w-100"
						style=" margin: 0;"
						:max="field.values.max"
						:min="field.values.min"
						v-model="formdata[field.key]"
					/><p
						class="body1 "
						style="position: absolute;
						left: 100%;
						margin-top: -34px;"
					>
						{{ formdata[field.key] }}
					</p>
				</div>
			</div>
		</div>
	</ParentForm>
	<div
		class="py-2 d-flex flex-column "
		:class="{'d-none': $route.name=='userDetail'}"
	>
		<div class="d-flex flex-row justify-content-center">
			<MatButton
				v-if="!$siteconf.cooptation"
				outlined
				:label="$route.name=='formByToken'? $t('cancel') : $t('return')"
				@click="prevStep"
				class="me-4"
			/>
			<MatButton
				:disabled="invalid || clicked"
				raised
				:label=" $route.name=='formByToken'? $t('save') : labelBtn"
				@click="nextStep"
			/>
		</div>
	</div>
</template>
<script>
import { ROUTES_NAME } from "@/router"
import { AlertLevel } from "@/components/application/confirm-dialog"
export default {
	emits: ['updateUser'],
	data() {
		return {
			advanced: {},
			formdata: [],
			clicked: false,
			invalid: false,
			isDisabled: true,
			firstData: [],
			ROUTES_NAME,
			savebyTokenError: false
		}
	},
	props: {
		data: {
			type: Object,
			default: () => null
		},
		formAns: {
			type: Object,
			default: () => { }
		},
		onNextClick: {
			type: Function,
			default: () => () => alert("next")
		},
		onPrevClick: {
			type: Function,
			default: () => () => alert("prev")
		},
	},
	watch: {
		data: {
			async handler() {
				this.advanced = this.data;
				const id = this.advanced?.id;
				// TODO: check that values are compatible with fields
				if(!this.formdata) this.formdata = this.$userData(null, id) ? this.$userData(null, id) : []

				if (this.formdata) this.firstData = JSON.parse(JSON.stringify(this.formdata));
				this.disabledButton()
			},
			immediate: true,
			deep: true
		},
		formAns: {
			handler() {
				let id = null
				if (this.advanced) id = this.advanced.id;
				if (!id) return;
				if(this.formAns && this.$siteconf.cooptation) this.formdata =this.formAns
				else this.formdata = this.$userData.load(id, this.formAns);
			},
			immediate: true
		},
		formdata: {
			handler() {
				const id = this.advanced.id;
				this.disabledButton()
				if (!id) return;
				this.$userData(null, id, this.formdata);
				
			},
			deep: true,
			immediate: true
		},
		$route: {
			handler() {
				if (this.$route.name == ROUTES_NAME.POST || this.$route.name == "formByToken") this.isDisabled = false
			}, immediate: true
		},

	},
	methods: {
		async nextStep() {
			this.clicked = true;
			if (this.$route.name == "formByToken") {
				//this.$emit("savebytoken", this.formdata)
				try {
					let answers = this.advanced.fields.map((el, index) => {
						if (this.formdata[index]) {
							return el.type === "date" ? this.$utils.isDate(this.formdata[index]) : this.formdata[index];
						} else {
							return undefined;
						}
					})
					await this.$service.candidature.saveFormByToken(this.$route.params.jwt, answers, this.advanced.id)
					this.clicked = false;
					this.$alert({
						title: this.$t("formulaire"),
						text: this.$t("formsentresp"),
						level: AlertLevel.info,

					});
					this.$router.push({
						name: ROUTES_NAME.HOME,
					});
				} catch (error) {
					this.savebyTokenError = true
					this.clicked = false;

					if (error.response && error.response.status == 401) this.$router.push({ name: 'EXPIRED', params: { config: "form" }, })
					else if (error.response && (error.response.status == 400 || error.response.status == 404 || error.response.status == 500)) {
						this.$alert({
							title: this.$t("formulaire"),
							text: this.$t("errorpost"),
							level: AlertLevel.error,
						});
						this.$router.push({
							name: ROUTES_NAME.HOME,
						});
					}
					else if (error.response && error.response.status == 403) {
						this.$alert({
							title: this.$t("formulaire"),
							text: this.$t("formsend"),
							level: AlertLevel.info,

						});
						this.$router.push({
							name: ROUTES_NAME.HOME,
						});
					}

				}

			}
			else if (this.$siteconf.cooptation) {
				this.onNextClick(this.formdata)
			}
			else this.onNextClick();
		},
		prevStep() {
			if (this.$route.name == "formByToken") this.$router.push(ROUTES_NAME.HOME)
			else this.onPrevClick()
		},
		disabledButton() {
			this.invalid = false
			if (this.advanced.fields && this.formdata) {
				//if (this.advanced.fields && this.formAns) {
				this.advanced.fields.forEach((element, index) => {
					if (element.mandatory && (!this.formdata[index] || this.formdata[index].length == 0)) {
						//if (element.mandatory && (!this.formAns[index] || this.formAns[index].length == 0)) {
						this.invalid = true
					}
				});
			}
		},
		editform(type) {
			//edit: show edit form 
			if (type == 'edit') { this.isDisabled = !this.isDisabled }

			else if (type == 'cancelUpdate') {
				this.isDisabled = true
				this.formdata = this.firstData;
				let id = null
				if (this.advanced) id = this.advanced.id;
				if (!id) return;
				this.$userData(null, id, this.formdata);
				this.firstData = JSON.parse(JSON.stringify(this.formdata));
			}
			else if (type == 'update') {
				const id = this.advanced.id;
				if (!id) return;

				let answers = this.advanced.fields.map((el, index) => {
					if (this.formdata[index]) {
						return el.type === "date" ? this.$utils.isDate(this.formdata[index]) : this.formdata[index];
					} else {
						return undefined;
					}
				})
				this.firstData = JSON.parse(JSON.stringify(this.formdata));
				this.$emit("updateUser", { "answers": answers, "id": id });

				this.isDisabled = true
			}
		},

	},
	computed: {
		labelBtn() {
			if (this.clicked) return this.$t('waiting');
			else { return this.$route.name == 'formByToken' ? this.$t('save') : this.$siteconf.cooptation ? this.$t('suiv') : this.$t('soumettreCandi') }

		},

	},
	mounted() {
		//this.advanced = this.data;
		if (!this.data) return this.onNextClick()

		document.body.scrollTop = 0;
	},
}
</script>
<style lang="scss" scoped>
.form-input {
	margin-top: -10px;
}
</style>