<template lang="">
	<ParentForm
		v-if="experience"
		:title="$t('exp.formText')"
		:text="text"
		headerClass="pb-2"
		@update="editform($event)"
		:disabled="isDisabled"
	>
		<template
			v-for="(e, index) in experience"
			:key="e"
		>
			<div
				class="flex-vertical-center  border-top"
				style="height: 48px"
			>
				<p
					class="h6 txt-disabled flex-max"
					ref="exp_title"
				>
					{{ expTitle(e)=='formTextVide'? $t('exp.'+expTitle(e)): expTitle(e) }}
				</p>
				<MatIconButton
					icon="delete_outline"
					class="text-primary"
					@click="removeExperience(index)"
					v-show="experience.length > 1"
				/>
				<MatIconButtonToggle
					ref="expExpand"
					onIcon="expand_less"
					offIcon="expand_more"
					class="material-icons d-flex text-primary"
				/>
			</div>
			<BsCollapsible :trigger="() => $refs.expExpand[index]">
				<Experience
					:data="e"
					:disabled="isDisabled"
				/>
			</BsCollapsible>
		</template>
	</ParentForm>
	<div
		class="p-4 d-flex border-top"
		:class="{'d-none': $route.name=='userDetail' && isDisabled}"
	>
		<MatButton
			:disabled="verifAdd"
			outlined
			icon="add_circle_outline"
			:label="$t('exp.add') "
			@click="addExperience"
			class=""
		/>
	</div>
	<div
		class="d-flex flex-row justify-content-center"
		:class="{'d-none': $route.params.step=='validation' || $route.name=='userDetail' }"
	>
		<MatButton
			outlined
			:label="$t('return')"
			@click="onPrevClick"
			class="me-4"
		/>
		<MatButton
			:disabled="verif"
			raised
			:label="$t('suiv')"
			@click="onNextClick"
		/>
	</div>
</template>
<script>
import Experience from "./blocks/experience-block.vue"
//import filter from "@/utils/filter"
import { ROUTES_NAME } from "@/router"

export const defaultExperience = {
	title: "",
	institution: "",
	start: null,
	end: null,
	still: true,
}

export default {
	components: {
		Experience
	},
	data() {
		return {
			experience: null,
			show: false,
			isDisabled: true,
			ROUTES_NAME

		}
	},
	emits: ['updateCandidData', 'updateUser'],
	computed: {
		expTitle() {
			return (e) => {
				let title = "";
				if (e.title) {
					title += e.title;
					if (e.institution) {
						title += this.$t('exp.a') + e.institution;
						if (e.start && e.still) {
							title += this.calculateDuration(e.start)

						}
						else if (e.start && e.end) {
							title += this.calculateDuration(e.start, e.end)

						}
					}
					//this.$t('hier')
				}
				return title || "formTextVide"
			}
		},

		verif() {
			let hasOneElemt = function (el) { return el.text || el.title }
			let vide = false
			if (!this.experience || !this.experience.length) return true;


			for (let el of this.experience) {
				if (!hasOneElemt(el)) continue;
				const date_e = new Date(el.end);
				const date_s = new Date(el.start);
				if (!el.still && date_e && date_s && date_s.getTime() >= date_e.getTime()) {
					vide = true;
					break;
				}
				if (!el.title || !el.institution || !el.start || !(el.end || el.still)) {
					vide = true;
					break;
				}

			}
			return vide;
		},
		verifAdd() {
			const exp = this.experience && this.experience[this.experience.length - 1];
			const date_e = exp && new Date(exp.end);
			const date_s = exp && new Date(exp.start);
			if (exp && !exp.still && date_s >= date_e) return true;
			return !(exp && exp.title && exp.institution && exp.start && (exp.end || exp.still))
		},
	},
	props: {
		data: {
			type: Object,
			default: () => null
		},
		title: {
			type: String,
			default: 'Décrivez votre parcours professionnels'
		},
		text: {
			type: String,
			default: ""
		},
		onNextClick: {
			type: Function,
			default: () => () => alert("next")
		},
		onPrevClick: {
			type: Function,
			default: () => () => alert("prev")
		},
	},
	watch: {
		data: {
			handler() { this.experience = this.data; }
		},
		experience: {
			handler() {
				if (this.$route.name == ROUTES_NAME.POST) this.$userData(null, 'exp', this.experience)

			},
			deep: true,
		},
	},
	async mounted() {
		document.body.scrollTop = 0;
		this.experience = this.$userData.load('exp', this.data);
		if (Object.keys(this.experience).length === 0) this.experience = this.data
	},
	methods: {
		editform(type) {
			//edit: show edit form 
			if (type == 'edit') this.isDisabled = !this.isDisabled
			else if (type == 'cancelUpdate') {
				this.experience = this.$userData.load('exp', this.data);
				this.isDisabled=true
			}
			//update: update localStorage et base 
			else if (type == 'update') {
				this.$userData(null, 'exp', this.experience)
				const getYearMonthDay = function (date) {
				return { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() }
			}
				let obj =  JSON.parse(JSON.stringify(this.experience ))
				obj.forEach(el => {
					if (el.start) {
						el.from = getYearMonthDay(new Date(el.start));
						delete el.start
					}
					if (el.still) {
						delete el.end
						delete el.still
					}
					else {
						el.to = getYearMonthDay(new Date(el.end))
						delete el.end
					}

				});
				this.$emit("updateUser",{"cv": {"experiences": obj}});
				this.isDisabled=true
			}
		},
		addExperience() {
			this.experience.push(JSON.parse(JSON.stringify({
				title: "",
				institution: "",
				start: null,
				end: null,
				still: false
			}
			)))
			setTimeout(() => {
				this.$refs.exp_title[this.experience.length - 1].scrollIntoView({ behavior: "smooth" })
			},)
			this.show = false
		},
		removeExperience(index) {
			this.experience.splice(index, 1)
		},
		experienceAdded() {
			this.show = true
		},
		calculateDuration(startDate, endDate) {
			if (!startDate) return "";
			if (endDate && startDate > endDate) {
				// Handle the case where startDate is greater than endDate
				return ""
			}
			const startYear = new Date(startDate).getFullYear();
			const startMonth = new Date(startDate).getMonth();
			const endYear = endDate ? new Date(endDate).getFullYear() : new Date().getFullYear();
			const endMonth = endDate ? new Date(endDate).getMonth() : new Date().getMonth();

			let years = endYear - startYear;
			let months = endMonth - startMonth;

			// If months is negative, borrow a year from years
			if (months < 0) {
				years--;
				months += 12;
			}
			let result = "";
			if (years > 0) {
				if (endDate)
					result += `${this.$t('exp.for')} ${years} ${this.$t(years !== 1 ? 'exp.years' : 'exp.year')}`;
				else
					result += `${this.$t('exp.since')} ${years} ${this.$t(years !== 1 ? 'exp.years' : 'exp.year')}`;

			}
			if (months > 0) {
				if (years == 0) {
					if (endDate) result += this.$t('exp.for');
					else result += this.$t('exp.since');
				}
				else result += this.$t('exp.end');
				result += ` ${months} ` + this.$t(months !== 1 ? 'exp.months' : 'exp.month');
			}

			return result;

			// Calculate the difference in years and months

		},
	}
}
</script>
<style lang="scss" scoped></style>
