<template lang="">
	<ParentForm
		v-if="education"
		:title="$t('formation.formText')"
		headerClass="pb-2 border-bottom "
		@update="editform($event)"
		:disabled="isDisabled"
	>
		<div
			v-for="(e, index) in education"
			:key="e"
			:class="{'border-bottom':index<education.length-1, 'pb-2': e.level == '-2'}"
		>
			<div class="flex-vertical-center ">
				<p class="h6 txt-secondary flex-max">
					{{ eduTitle(e)=='formTextVide'? $t('formation.'+eduTitle(e) ): eduTitle(e) }}
				</p>
				<MatIconButton
					v-show="education.length > 1"
					icon="delete_outline"
					class="text-primary"
					@click="removeEducation(index)"
				/>
				<MatIconButtonToggle
					ref="educationExpand"
					onIcon="expand_less"
					offIcon="expand_more"
					class="material-icons d-flex text-primary"
				/>
			</div>
			<BsCollapsible :trigger="() => $refs.educationExpand[index]">
				<Education
					:data="e"
					:disabled="isDisabled"
				/>
			</BsCollapsible>
		</div>
		<div
			class="p-4 d-flex border-top"
			:class="{'d-none': $route.name=='userDetail' && isDisabled}"
		>
			<MatButton
				:disabled="verifAdd"
				outlined
				icon="add_circle_outline"
				:label="$t('formation.add')"
				@click="addEducation"
			/>
		</div>
	</ParentForm>
	<div
		class="d-flex flex-row justify-content-center" 
		:class="{'d-none': $route.params.step=='validation'|| $route.name=='userDetail' }"
	>
		<MatButton
			outlined
			:label="$t('return')"
			@click="onPrevClick"
			class="me-4"
		/>
		<MatButton
			:disabled="verif"
			raised
			:label="form || !this.$siteconf.lightCv ? $t('suiv'): $t('soumettreCandi')"
			@click="onNextClick"
		/>
	</div>
</template>
<script>
import Education from "./blocks/education-block.vue"
import { ROUTES_NAME } from "@/router"

//import filter from "@/utils/filter"
export const defaultEducation = {
	title: "",
	institution: "",
	location: "",
	start:null,
	still:false

}
export default {
	components: {
		Education
	},
	data() {
		return {
			education: null,
			isDisabled: true,
			ROUTES_NAME
		}
	},
	emits: ['updateCandidData', 'updateUser'],
	props: {
		data: {
			type: Object,
			default: () => null
		},
		title: {
			type: String,
			default: "Décrivez votre formation"
		},
		text: {
			type: String,
			default: ""
		},
		form: {
			type: Boolean,
			default: () => false
		},
		onNextClick: {
			type: Function,
			default: () => () => alert("next")
		},
		onPrevClick: {
			type: Function,
			default: () => () => alert("prev")
		},
	},
	watch: {
		data() {
			this.education = this.data;
		},
		education:{
			handler() {
				if (this.$route.name == ROUTES_NAME.POST) this.$userData(null, 'edu', this.education);
			},
			deep: true,
		}
	},
	async mounted() {
		document.body.scrollTop = 0;
		this.education = this.$userData.load('edu', this.data);
		if(Object.keys(this.education).length === 0) this.education=this.data

	},
	computed: {
		eduTitle() {
			return (e) => {
				let title = "";
				if (e.title) {
					title += e.title;
					if (e.institution) {
						title += this.$t('exp.a') + e.institution;}
						if (e.start) {
							title += ', ' + e.start
						}
						if (e.location) {
							title += ", "
							title += this.$t('exp.a') + e.location
						}
					

				}
				return title || "formTextVide"
			}
		},
		verif() {
			let hasOneElemt = function(el){ return el.text || el.title || el.level}
			let vide = false
			if(!this.education?.length) return true;
			if(this.education.length===1 && !hasOneElemt(this.education[0])) return true;
			for(let el of this.education){
				if (el.level === '-2') break;
				if(!hasOneElemt(el)) continue;
				if (!el.title || !(el.start || el.still) || !el.level){
					vide = true;
					break;
				}
			}
			return vide
		},
		verifAdd() {
				const edc = this.education && this.education[this.education.length - 1];
			return edc.level === '-2' || !(edc?.title && (edc.start || edc.still) && edc.level)
		},
},
	
	methods: {
		addEducation() {
			this.education.push(JSON.parse(JSON.stringify(defaultEducation)))
			setTimeout(() => {
				//	this.$refs.edu_title[this.education.length - 1].scrollIntoView({behavior: "smooth"})
			},)
		},
		removeEducation(index) {
			this.education.splice(index, 1)
		},
		editform(type) {
			//edit: show edit form 
			if (type == 'edit') this.isDisabled = !this.isDisabled
			else if (type == 'cancelUpdate') {
				this.education = this.$userData.load('edu', this.data);
				this.isDisabled=true
			}
			//update: update localStorage et base 
			else if (type == 'update') {
				this.$userData(null, 'exp', this.education)
				let obj = JSON.parse(JSON.stringify(this.education ))
				const getYearMonthDay = function (date) {
				return { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() }
			}
				obj.forEach(el => {
					if (el.start) {
						el.from = getYearMonthDay(new Date(el.start));
						delete el.start
					}
					if (el.still) {
						delete el.still
					}
				});
				this.$emit("updateUser",{"cv": {"educations": obj }});
				this.isDisabled=true
			}
		},
	}

}
</script>
<style lang="scss" scoped></style>
