<template lang="">
	<div
		class="entreprise-header overflow-hidden d-none d-lg-block"
		:class="[$siteconf?.banner ? 'banner' : 'bg-primary' ]"
	>
		<div
			class="blur-background"
			v-if="$siteconf?.banner"
		/>
		<div
			class="position-relative header-content container-xxl"
		>
			<div class="d-none d-lg-block">
				<h1 class="h3 text-white text-center mb-3 titleblur">
					{{ $siteconf.client }}
				</h1>
			</div>
			<div class="position-absolute headerButton">
				<div class=" post-section me-3 me-lg-5">
					<div class="subtitle2 text-white mb-3 ms-2 ms-lg-0">
						<div
							class="d-flex mt-2"
							:class="[{'justify-content-center ms-3': (home.stats?.positions== undefined )},
								{'justify-content-center': (home.stats?.offers== undefined )}
							]"
						>
							<router-link
								:to="{ name: ROUTES_NAME.OFFERS }"
								v-if="home.stats?.offers"
							>
								<div
									class="flex-vertical-center headerdata pe-3 me-3"
								>
									<MatIconButton
										mini
										icon="plagiarism" 
										class="d-none d-lg-block text-white"
									/>
									<h6
										class="text-white ms-3 ms-lg-0"
										v-if="home.stats?.offers"
									>
										{{ home.stats?.offers }}&nbsp;
									</h6>
									<div
										class="subtitle1 text-white"
									>
										{{ (home.stats?.offers ==0) ? $t(`noOffer`) : (home.stats?.offers ==1) ? $t(`oneOffer`) : $t(`offers`) }}
									</div>
								</div>
							</router-link>
							<router-link
								:to="{ name: ROUTES_NAME.OFFERS }"
								v-if="home.stats?.positions != undefined"
							>
								<div 
									class="flex-vertical-center headerdata pe-3"
								>
									<MatIconButton
										mini
										icon="person_search" 
										class=" d-none d-lg-block text-white"
									/>
									<h6
										class="text-white ms-3 ms-lg-0"
										v-if="home.stats?.positions"
									>
										{{ home.stats?.positions }}&nbsp;
									</h6>
									<div
										class="subtitle1 text-white"
									>
										{{ (home.stats?.positions ==0) ? $t(`noPosition`) : (home.stats?.positions ==1) ? $t(`onePosition`) : $t(`positions`) }}
									</div>
								</div>
							</router-link>
						</div>
					</div>
					<router-link
						:to="{ name: ROUTES_NAME.OFFERS }"
						v-if="home.stats?.offers"
					>
						<MatButton
							outlined
							class="post-resume bg-white w-100 d-none d-lg-block"
						>
							{{ $t(`voirTO.lg`) }}
						</MatButton>
						<MatButton
							outlined
							class="post-resume bg-white w-100 d-block d-lg-none"
						>
							{{ $t(`voirTO.sm`) }}
						</MatButton>
					</router-link>
				</div>
			</div>
		</div>
	</div>

	<span
		class="d-block d-lg-none "
	>
		<div
			v-if="scrollpx<10"
			class="entreprise-header overflow-hidden"
			:class="[$siteconf?.banner ? 'banner' : 'bg-primary' ]"
		>
			<div
				class="blur-background"
				v-if="$siteconf?.banner"
			/>
			<div
				class="position-relative header-content container-xxl"
			>
				<div class="d-block d-lg-none w-90 ">
					<h1 class="h3 text-white mb-3 text-center ecli text-break titleblur">
						{{ $siteconf.client }}
					</h1>
				</div>

				<div class="position-absolute headerButton">
					<div class="subtitle2 text-white mb-3 ms-2 ms-lg-0">
						<div class="d-flex mt-2 me-3">
							<router-link
								class="me-3"
								:to="{ name: ROUTES_NAME.OFFERS }"
								v-if="home.stats?.offers"
							>
								<div
									class="flex-vertical-center headerdata pe-3 "
									v-if="home.stats?.offers !== undefined"
								>
									
									<h6 class="text-white ms-3 ms-lg-0">
										{{ home.stats?.offers }}&nbsp;
									</h6>
									<div
										class="subtitle1 text-white"
										v-if="home.stats?.offers"
									>
										{{ (home.stats?.offers > 1) ? $t(`offers`) : $t(`oneOffer`) }}
									</div>
									<div
										class="subtitle1 text-white defautCur"
										v-else
									>
										{{ $t(`noOffer`) }}
									</div>
								</div>
							</router-link>
							<router-link
								:to="{ name: ROUTES_NAME.OFFERS }"
								v-if="home.stats?.positions!== undefined "
							>
								<div
									class="flex-vertical-center headerdata pe-3"
								>
										
									<h6 class="text-white ms-3 ms-lg-0">
										{{ home.stats?.positions }}&nbsp;
									</h6>
									<div
										class="subtitle1 text-white"
										v-if="home.stats?.positions"
									>
										{{ (home.stats?.positions > 1) ? $t(`positions`) : $t(`onePosition`) }}
									</div>
									<div
										class="subtitle1 text-white defautCur"
										v-else
									>
										{{ $t(`noPosition`) }}
									</div>
								</div>
							</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
		<headerContainer
			:title="$siteconf.client"
			type="nobg"
			v-if="scrollpx>10"
			class=" position-fixed w-100 "
		/>
	</span>

	<div
		class="p-2 p-lg-5 bg-white "
	>
		<div
			class="d-flex flex-row container-xxl text-center"
			:class="[{'justify-content-center':!$siteconf.description || $siteconf.description.length<100 },{'text-lg-start': !$siteconf.hideImages}]"
		>
			<div
				class="ps-0 ps-lg-3"
			>
				<div
					class="d-flex sect2 justify-content-center"
				>
					<span 
						class="me-3"
					>
						<img
							v-if="$siteconf?.logo"
							style="max-width: 200px;max-height: 120px;"
							src="/c/logo.png"
							alt="logo"
							id="logo"
						> 
						<h6
							class=" mt-3 col1"
							style="white-space:pre-line"
							v-if="$siteconf.description"
						>
							{{ $siteconf.description }}
						</h6>
							
					</span>
					<img
						ref="Image"
						v-if="!$siteconf.hideImages?.home"
						alt="Description"
						class="ps-3 h-100 w-80 imgSect2"
						:src="imageSrc"
					>
				</div>
			
				<div
					class="d-flex flex-column pt-3 socialIcon"
					:class="($siteconf.description && home.length >80)? 'w-fit': 'justify-content-center'"
				>
					<div
						class="social mb-3 d-flex justify-content-center "
						:class="{'d-none':!home.social_networks}"
					>
						<MatFab
							outlined
							mini
							class="fa-border iconborder me-2 mb-2 "
							:class="{'d-none':!home.social_networks?.facebook}"
							@click="share('fb')"
						>
							<i
								class="fa-brands fa-facebook-f text-primary "
								slot="icon"
							/>
						</MatFab>
						<MatFab
							outlined
							mini
							class="fa-border iconborder me-2 mb-2"
							:class="{'d-none':!home.social_networks?.instagram}"
							@click="share('instagram')"
						>
							<i
								class="fa-brands fa-instagram text-primary "
								slot="icon"
							/>
						</MatFab>
						<MatFab
							outlined
							mini
							class="fa-border iconborder me-2 mb-2"
							:class="{'d-none':!home.social_networks?.youtube}"
							@click="share('yt')"
						>
							<i
								class="fa-brands fa-youtube text-primary "
								slot="icon"
							/>
						</MatFab>
						<MatFab
							outlined
							mini
							class="fa-border iconborder me-2 "
							:class="{'d-none':!home.social_networks?.linkedin}"
							@click="share('linkedin')"
						>
							<i
								class="fa-brands fa-linkedin-in text-primary "
								slot="icon"
							/>
						</MatFab>
						<MatFab
							outlined
							mini
							class="fa-border iconborder me-2 "
							:class="{'d-none':!home.social_networks?.twitter}"
							@click="share('twitter')"
						>
							<i
								class="fa-brands fa-x-twitter text-primary "
								slot="icon"
							/>
						</MatFab>
					</div>
					<div class="d-flex justify-content-center">
						<a
							:href="$siteconf.website"
							target="_blank"
						>
							<MatButton
								v-if="$siteconf.website"
								outlined
								class=" small"
								:label="$siteconf.websiteTitle || $t(`clientWebsite`).replace('_', $siteconf.client || '')"
								style="width:250px"
							/>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div
		class="d-flex pb-5 bg-primary"
		v-if="( home.stats?.offers || $siteconf.spontaneous_app)"
	>
		<span class="d-flex flex-max container-xxl">
			<div class="col-2 ps-4 d-flex align-items-end">
				<SvgIcon 
					icon="plane"
					size="93"
				/>
			</div>
			<div class="col-8 ">
				<h2 class="h4 text-white text-center pt-5 d-block textShadowDark iTravaille">
					{{ home.applyPrompt || $t(`iTravaille`) }}
				</h2>
				<h6
					class="text-white text-center pt-4 textShadowDark envoiCandid"
					v-if="$siteconf.spontaneous_app"
				>
					{{ home.applyPromptSubtext || $t(`envoiCandid`) }}
				</h6>
				<h6
					class="text-white text-center pt-4 textShadowDark envoiCandid"
					v-else
				>
					{{ home.applyPromptSubtext || $t(`iTravailleSubtext`) }}
				</h6>

				<div class="d-flex flex-wrap justify-content-center pt-3">
					<router-link
						class="me-2"
						:to="{name:ROUTES_NAME.POST, params:{offerId:'0', name: 'candidature_spontanee'}}"
						v-if="$siteconf.spontaneous_app"
					>
						<MatButton
							raised
							class="me-0 me-lg-3 border mb-3"
							style="box-shadow: none;"
							:label=" $t(`spontanee`) "
						/>
					</router-link>

					<router-link
						:to="{ name: ROUTES_NAME.OFFERS }"
						v-if="home.stats?.offers"
					>
						<MatButton
							outlined
							class="bg-white "
							:label="$t(`voirTO.lg`)" 
						/>
					</router-link>
				</div>
			</div>
			<div class="col-2 pt-2 text-end d-none d-lg-block">
				<SvgIcon
					icon="line"
					size="120"
				/>
			</div>  
			<div class="col-2 pt-2 text-end d-block d-lg-none ">
				<SvgIcon
					icon="line"
					size="40"
				/>
			</div>  
		</span>
	</div>
	<div
		class="bg-white pb-5"
		v-if="home.offers?.length>0"
	>
		<div class="d-flex flex-row container py-5 ">
			<h2 class="h4 text-start iTravaille">
				{{ $t(`recent`) }}
			</h2>
		</div>    
		<div
			class="container sectionBotton"
		>
			<div
				class="row sect4 justify-content-center"
			>
				<div
					class="col-3 mb-2 size"
					:class="{'col-4': home.stats?.offers < 4}"
					v-for="item in home.offers"
					:key="item.id"
				>
					<OfferCard :offer="item" />
				</div>  
				<div
					class="col-3 size "
					v-if="home.stats?.offers > 3"
				>
					<section class=" bg-primary h-100 ">
						<div class="center text-center">
							<h6 class="text-white ">
								{{ home.stats?.offers }} {{ (home.stats?.offers > 1) ? $t(`offers`) : $t(`oneOffer`) }}
							</h6>
							<h6
								class="text-white"
								v-if="home.stats?.positions !== undefined"
							>
								{{ home.stats?.positions }} {{ (home.stats?.positions > 1) ? $t(`positions`) : $t(`onePosition`) }}
							</h6> 
							<router-link
								:to="{ name: ROUTES_NAME.OFFERS }"
							>
								<MatButton
									class="bg-white mt-5"
									:label="$t(`explore`)" 
									outlined
								/>
							</router-link>
						</div>
					</section>
				</div>
			</div>
		</div>
	</div>
	<div
		class="bg-primary-light pb-5 d-none"
	>
		<span class="container">
			<h6 class="text-start text-lg-center py-5 container-xxl d-block d-lg-none">
				{{ $t(`interest`) }}
			</h6>
			<h4 class="text-start text-lg-center py-5 container-xxl d-none d-lg-block">
				{{ $t(`interest`) }}
			</h4>
			<div class="d-flex sect4 container-xxl px-2 px-lg-5 "> 
				<section
					class="me-4 contractCard size-sm"
					v-for="(item) in [0,1,2,3]"
					:key="item"
				>
					<div>
						<img
							
							class="position-relative w-100 "
						>
						<div class="card-desc">
							<h6 class="text-white text-center shadowEffect">
								Contrats  CDI 
							</h6>
							<div>
								<div class="justify-content-center flex-vertical-center mt-3">
									<MatIconButton
										mini
										icon="plagiarism" 
										class="text-white"
									/>
									<h6 class="text-white ">
										12&nbsp;
									</h6>
									<div class="subtitle1 text-white ">
										Offres
									</div>
								</div>
								<div class="justify-content-center flex-vertical-center">
									<MatIconButton
										mini
										icon="person_search" 
										class="text-white"
									/>
									<h6 class="text-white ">
										133&nbsp;
									</h6>
									<div class="subtitle1 text-white ">
										Postes
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</span>
	</div>
</template>
<script>
import OfferCard from "@/components/application/offer-card.vue";
import { ROUTES_NAME } from "@/router";
import headerContainer from "@/components/application/header-caintainer.vue"
import { imageErrorHandling } from "@/../public/js/imageErrorHandling"

export default {
	name: "HomePage",
	mixins: [imageErrorHandling],
	components: {
		OfferCard,
		headerContainer,
	},
	data() {
		return {
			scrollpx: 0,
			ROUTES_NAME,
			home: {},
			imageSrc: "",
			fallbackImageSrc: "/s/images/Group.png"
		}
	},
	created() {
		if (this.$siteconf.hideHome) this.$router.push(ROUTES_NAME.OFFERS)
	},
	async serverPrefetch() { this.home = await this.$http.get('home'); },
	async mounted() {
		if (this.$route.name == "verify_account" && this.$route.params.token) {
			await this.$service.appservice.verifyAccount(this.$route.params.token)
			this.$store.commit('setToken');
		}
		this.$http.get('home').then(r => this.home = r);
		window.addEventListener('scroll', this.handleScroll);
	},
	unmounted() {
		window.removeEventListener('scroll', this.handleScroll);
	},
	updated(){
		if(this.home.customImage) this.imageSrc="/c/images/home"
		this.preloadImage(this.imageSrc, this.fallbackImageSrc);
	},
	methods: {
		calculateDominantColor(imageData) {
			var colorMap = {};
			var maxCount = 0;
			var dominantColor = null;

			// Iterate through the pixel data
			for (var i = 0; i < imageData.length; i += 4) {
				var r = imageData[i];
				var g = imageData[i + 1];
				var b = imageData[i + 2];
				var alpha = imageData[i + 3];

				// Check if the pixel is not white
				if (r !== 255 && g !== 255 && b !== 255 && alpha !== 0) {
					var color = 'rgb(' + r + ',' + g + ',' + b + ')';

					// Count occurrences of each color
					colorMap[color] = (colorMap[color] || 0) + 1;

					// Update the dominant color if necessary
					if (colorMap[color] > maxCount) {
						maxCount = colorMap[color];
						dominantColor = color;
					}
				}
			}

			return dominantColor;
		},
		handleScroll() {
			this.scrollpx = window.scrollY;
		},
		share(rs) {
			//e.preventDefault()
			let url = "";
			if (rs == "rb")
				url = this.home.social_networks.facebook;
			else if (rs == "instagram")
				url = this.home.social_networks.instagram;
			else if (rs == "yt")
				url = this.home.social_networks?.youtube;
			else if (rs == "linkedin")
				url = this.home.social_networks?.linkedin;
			else
				url = this.home.social_networks?.twitter;
			this.$utils.share(rs, url)
		},

	}
}
</script>

<style scoped lang="scss">
.entreprise-header {
	position: relative;
	background-size: cover;

	.post-section {
		.post-resume {
			--mdc-button-outline-color: var(--primary-color);
		}

		@media (max-width: 991px) {
			display: flex !important;

			.subtitle2 {
				padding-inline-end: 20px;
			}
		}

	}
}

.headerButton {
	bottom: 20px;
	right: 0px;

	@media (max-width: 991px) {
		bottom: 0px;
	}
}

.siteButton {
	bottom: 0;
	right: 0;
}

.headerdata {
	border-radius: 20px;
	background: rgba(255, 255, 255, 0.4);
	backdrop-filter: blur(10px);
}

.img {
	padding-bottom: 15px;
	padding-top: 15px;
	width: 100%;
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 50%);
	bottom: 0;

	.shadowEffect {
		text-shadow: 2px 2px black;
	}
}

.iconborder {
	border-color: var(--primary-color);
	box-shadow: none;

}

.small {
	min-width: 150px !important;
}

.social {
	min-width: 233px !important;
}

.socialIcon {
	@media (max-width: 991px) {
		text-align: center !important;
		width: unset;
	}

}

.center {
	position: relative;
	top: 55%;
	transform: translateY(-55%);
}

.contractCard {
	.shadowEffect {
		text-shadow: 2px 2px black;
	}

	.card-desc {
		position: absolute;
		background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 20%);
		bottom: 0;
		padding-bottom: 15px;
		padding-top: 20px;
		height: 100%;
		width: 100%;
		transform: translateY(65%);
		transition: 1s;
	}

	&:hover {
		.card-desc {
			transform: translateY(20%);
		}
	}
}

.sect2 {
	@media (max-width: 991px) {
		flex-wrap: wrap !important;

		.imgSect2 {
			min-width: 250px;
			width: 50% !important;
		}
	}

	.imgSect2 {
		max-width: 45%;
	}

}

.sect4 {
	@media (max-width: 1200px) {
		display: flex;
		flex-wrap: wrap;
		//overflow: auto;
	}

	.size {
		min-width: 265.5px;
		min-height: 265.5px;
	}

	.size-sm {
		min-width: 261px;
	}

}

.smPlane {
	top: 25px;
	position: relative;
}

.ecli {
	width: 100%;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
}

@media(max-width:991px) {
	.iTravaille {
		font-size: 1.25rem;
		font-weight: 500;
		line-height: 2rem;
		letter-spacing: 0.15px;
	}

	.envoiCandid {
		font-size: 1rem;
		font-weight: 400;
		line-height: 1.75rem;
		letter-spacing: 0.15px;
	}

	.line {
		height: 60px !important;
	}
}

@media(min-width:991px) {
	.line {
		height: 120px !important;
	}
}

.dominant-color {
	width: 50px;
	height: 50px;
	border: 1px solid #000;
	
}
</style>
