export default class User{
	constructor(http) { this.http = http }

    getUser(){
		return this.http.get(`user`);
	}
	getUserCandidature(){
		return this.http.get(`/c/applications`);
	}
	updateUser(data ){
		return this.http.post(`/c/user/data`, data);
	}
	getForm(){
		return this.http.get(`/c/questionnaire`);
	}
	postForm(data){
		return this.http.post(`/c/questionnaire`,data);
	}

}